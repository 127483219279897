import { ark, type HTMLArkProps } from '@ark-ui/solid';
import { tv, type VariantProps } from 'tailwind-variants';
import { mergeProps, splitProps } from 'solid-js';
import { twMerge } from 'tailwind-merge';
import { getAppliedClasses } from '~/utils/commonUtils.ts';
import SkLoader from '~/components/ui/SkLoader';
import './button.scss';

type ButtonFunctionalProps = {
  loading?: boolean;
};
type ButtonVariantProps = VariantProps<typeof styles>;
export type ButtonProps = ButtonVariantProps &
  HTMLArkProps<'button'> &
  ButtonFunctionalProps;

const styles = tv({
  base: 'button',
  defaultVariants: { variant: 'solid', size: 'md', color: 'primary' },
  variants: {
    variant: {
      solid: 'button--variant_solid',
      outline: 'button--variant_outline',
      ghost: 'button--variant_ghost',
      link: 'button--variant_link'
    },
    size: {
      'xs': 'button--size_xs',
      'sm': 'button--size_sm',
      'md': 'button--size_md',
      'lg': 'button--size_lg',
      'xl': 'button--size_xl',
      '2xl': 'button--size_2xl'
    },
    color: {
      primary: 'button--color_primary',
      secondary: 'button--color_secondary',
      danger: 'button--color_danger',
      info: 'button--color_info',
      skPrimary: 'button--color_sk_primary'
    }
  }
});
// export const Button = styled<ButtonProps>(ark.button, styles);
export const Button = (props: ButtonProps) => {
  const merged = mergeProps(
    {
      loading: false
    },
    props
  );
  const [componentProps, nativeButtonProps] = splitProps(merged, [
    'size',
    'variant',
    'loading',
    'color'
  ]);
  const [functionalProps, variantProps] = splitProps(componentProps, [
    'loading'
  ]);
  const classVariants = styles(variantProps);
  return (
    <ark.button
      {...nativeButtonProps}
      class={twMerge(
        'relative',
        classVariants,
        nativeButtonProps.class,
        getAppliedClasses(nativeButtonProps.classList || {})
      )}
    >
      <span
        class={`inline-flex items-center justify-between`}
        classList={{
          invisible: functionalProps.loading,
          visible: !functionalProps.loading
        }}
      >
        {props.children}
      </span>
      <span
        class={`flex absolute top-0 right-0 bottom-0 left-0`}
        classList={{
          invisible: !functionalProps.loading,
          visible: functionalProps.loading
        }}
      >
        <SkLoader width={16} />
      </span>
    </ark.button>
  );
};
