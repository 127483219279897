import { JSXElement } from 'solid-js';
import ArrowIcon from '~/assets/arrow.svg';
import NamedIcon from '~/components/ui/NamedIcon';
import { useAppData } from '~/components/AppData';
import EnvironmentIcon from '~/components/Layout/Header/SwitcherButtons/EnvironmentSwitcher/EnvironmentIcon';
import { EnvironmentTypes } from '~/api';
import { Localized } from '~/i18n';
import { getTranslationKey } from '~/pages/Quickstart/utils.ts';

export default function WorkspaceAndEnvironmentDescription(): JSXElement {
  const appData = useAppData();
  return (
    <div class={'flex flex-row'}>
      <div class={'flex flex-row'}>
        <div>
          <img
            src={ArrowIcon}
            alt="downarrow"
          />
        </div>
        <div class="mx-2 relative top-[20px]">
          <NamedIcon
            w={43}
            name={appData.workspace?.displayName}
            class={`workspace-logo`}
          />
          <div
            class={
              'text-white font-[Caveat] font-normal text-2xl mt-3 w-[170px]'
            }
          >
            <Localized translationKey={getTranslationKey('manage_workspace')} />
          </div>
        </div>
      </div>
      <div class={'flex flex-row'}>
        <div>
          <img
            src={ArrowIcon}
            alt="downarrow"
          />
        </div>
        <div class="mx-2 relative top-6">
          <div class={'h-9 w-10 rounded bg-white'}>
            <EnvironmentIcon
              environmentType={
                appData.selectedEnvironment?.type ||
                EnvironmentTypes.DEVELOPMENT
              }
            />
          </div>
          <div
            class={
              'text-white font-[Caveat] font-normal text-2xl mt-3 w-[250px]'
            }
          >
            <Localized
              translationKey={getTranslationKey('manage_environments')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
