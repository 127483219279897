const workerTimer = () => {
  self.addEventListener('message', function (e) {
    const { event, expiryTime } = e.data;
    let timerInterval,
      isWarningMessageSent = false,
      isExpirationMessageSent = false;
    switch (event) {
      case 'startTimer': {
        timerInterval = setInterval(() => {
          if (expiryTime) {
            let timeLeft = expiryTime - Date.now();

            // Trigger event when link is about to expire in 5 minutes
            if (
              timeLeft <= 5 * 60 * 1000 &&
              timeLeft > 0 &&
              !isWarningMessageSent
            ) {
              isWarningMessageSent = true;
              self.postMessage({ eventType: 'warning' });
            }

            // Trigger event when link is expired
            if (timeLeft <= 0 && !isExpirationMessageSent) {
              isExpirationMessageSent = true;
              isWarningMessageSent = true;
              self.postMessage({ eventType: 'expiry' });
              clearInterval(timerInterval);
            }
          }
        }, 100);
        break;
      }
      case 'stopTimer': {
        clearInterval(timerInterval);
        break;
      }
    }
  });
};

let code = workerTimer.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const workerScript = URL.createObjectURL(blob);

export default workerScript;
