import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  onCleanup,
  Show
} from 'solid-js';
import { createQuery } from '@tanstack/solid-query';
import { miscConstants } from '~/consts.ts';
import { globalStore, setGlobalStore } from '~/stores';
import {
  createPortalSession,
  getPortalSessionExpiry,
  getPortalSessionExpiryQuery,
  getPortalSessionQuery,
  retryAuthorizedRequests
} from '~/api';
import SkLoader from '~/components/ui/SkLoader';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import { postClientNotificationMessage } from '~/utils/clientNotifications/utils';
import { getLocalizedString } from '~/i18n/utils';
import { useIntl } from '@cookbook/solid-intl';
import workerScript from '~/utils/workers/workerTimerScript';

type PortalAuthenticationProps = {
  children: JSXElement;
};

export default function PortalAuthentication(
  props: PortalAuthenticationProps
): JSXElement {
  const merged = mergeProps({}, props);
  const intl = useIntl();
  const [isLoaded, setIsLoaded] = createSignal(false);

  const params = new URLSearchParams(new URL(window.location.href).search);

  const portalSessionExpiryQuery = createQuery(() => ({
    queryKey: getPortalSessionExpiryQuery(),
    queryFn: async () => await getPortalSessionExpiry(),
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  const timerWorker = new Worker(workerScript, { type: 'module' });

  if (params.has(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_LINK_ID)) {
    const portalSessionQuery = createQuery(() => ({
      queryKey: getPortalSessionQuery(),
      queryFn: async () =>
        await createPortalSession(
          params.get(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_LINK_ID) || ''
        ),
      retry: retryAuthorizedRequests
    }));

    createEffect(() => {
      if (portalSessionQuery.isSuccess) {
        setIsLoaded(true);
        portalSessionQuery.data.accessToken &&
          setGlobalStore(
            'portalAuthToken',
            portalSessionQuery.data.accessToken
          );
        portalSessionExpiryQuery.refetch();
      } else if (portalSessionQuery.isError) {
        throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
      }
    });
  } else if (
    params.has(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN)
  ) {
    setGlobalStore(
      'portalAuthToken',
      params.get(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN || '')
    );
    setGlobalStore('shouldForwardToken', true);
    setIsLoaded(true);
    portalSessionExpiryQuery.refetch();
  } else {
    setIsLoaded(true);
    portalSessionExpiryQuery.refetch();
  }

  createEffect(() => {
    const messageHandler = (e: MessageEvent) => {
      if (e.data.eventType === 'warning') {
        postClientNotificationMessage({
          event_type: 'PORTAL_SESSION_WARNING',
          message: getLocalizedString(
            `admin_portals.link_expiry.portal_session_warning`,
            { intl }
          ),
          session_expiry: globalStore.portalSessionExpiry
        });
      }

      if (e.data.eventType === 'expiry') {
        postClientNotificationMessage({
          event_type: 'PORTAL_SESSION_EXPIRY',
          message: getLocalizedString(
            `admin_portals.link_expiry.portal_session_expiry`,
            { intl }
          ),
          session_expiry: globalStore.portalSessionExpiry
        });
      }
    };

    timerWorker.addEventListener('message', messageHandler);

    onCleanup(() => {
      timerWorker.removeEventListener('message', messageHandler);
    });
  });

  createEffect(() => {
    if (portalSessionExpiryQuery.isSuccess) {
      const expiryTime =
        portalSessionExpiryQuery.data.sessionExpiry ||
        portalSessionExpiryQuery.data.accessTokenExpiry;

      const expiryTimeInMilli = new Date(expiryTime);
      setGlobalStore('portalSessionExpiry', expiryTime);
      timerWorker.postMessage({
        event: 'startTimer',
        expiryTime: expiryTimeInMilli
      });
    } else if (portalSessionExpiryQuery.isError) {
      throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
    }

    onCleanup(() => {
      timerWorker.postMessage({ event: 'stopTimer' });
    });
  });

  return (
    <Show
      when={isLoaded()}
      fallback={<SkLoader />}
    >
      {merged.children}
    </Show>
  );
}
