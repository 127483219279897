export enum QueryKeys {
  FEATURE_FLAGS = 'feature_flags',
  RESOURCE_CONTEXT = 'resource_context',
  ENVIRONMENT_FEATURES = 'env_features',
  LOGGED_IN_MEMBER_DETAILS = 'logged_in_member_details',
  CURRENT_WORKSPACE_DETAILS = 'current_workspace_details',
  WORKSPACE_ENVIRONMENTS_LIST = 'workspace_environmets_list',
  ORGANIZATIONS = 'organizations',
  USERS = 'users',
  ORGANIZATIONS_SEARCH = 'organizations_search',
  CONNECTIONS = 'connections',
  DIRECTORIES = 'directories',
  ORGANIZATION_EVENTS = 'organization_events',
  DIRECTORY_USERS = 'directory_users',
  DIRECTORY_GROUPS = 'directory_groups',
  DIRECTORY_GROUPS_FROM_GBRA = 'directory_groups_gbra',
  CLIENT_CREDENTIALS = 'client_credentials',
  MEMBERS_LIST = 'members_list',
  INVITE = 'invite',
  DOMAINS = 'domains',
  PORTAL_SESSION = 'portal_session',
  PORTAL_SESSION_EXPIRY = 'portal_session_expiry',
  USER_PROFILE_ATTRIBUTES = 'user_profile_attributes',
  PORTAL_CUSTOMIZATIONS = 'portal_customizations',
  SOCIAL_CONNECTIONS = 'social_connections',
  SOCIAL_CONNECTION_DETAILS = 'social_connection_details',
  ENVIRONMENT_WEBHOOK_PORTAL_LINK = 'webhook_portal_link',
  ENVIRONMENT_ROLES = 'env_roles',
  WORKSPACE_SUBSCRIPTIONS = 'workspace_subscriptions',
  WORKSPACE_PRICING_TABLE = 'workspace_pricing_table',
  WORKSPACE_BILLING_CUSTOMER_PORTAL = 'workspace_customer_portal',
  ENVIRONMENT_WEBHOOK_ENDPOINTS = 'environment_webhook_endpoints',
  ENVIRONMENT_WEBHOOK_EVENT_TYPES = 'environment_webhook_event_types',
  CUSTOM_DOMAIN_GET_DNS_RECORDS = 'custom_domain_get_dns'
}

const getFeatureFlagsQueryKey = () => [QueryKeys.FEATURE_FLAGS];

const getResourceContextQueryKey = () => [QueryKeys.RESOURCE_CONTEXT];

const getEnvironmentFeaturesQueryKey = () => [QueryKeys.ENVIRONMENT_FEATURES];

const getCurrentLoggedInMemeberKey = () => [QueryKeys.LOGGED_IN_MEMBER_DETAILS];

const getCurrentWorkspaceKey = () => [QueryKeys.CURRENT_WORKSPACE_DETAILS];

const getOrganizationsKey = (invalidator?: string) =>
  invalidator
    ? [QueryKeys.ORGANIZATIONS, invalidator]
    : [QueryKeys.ORGANIZATIONS];

const getOrganizationsSearchKey = (query?: string) =>
  query
    ? [QueryKeys.ORGANIZATIONS_SEARCH, query]
    : [QueryKeys.ORGANIZATIONS_SEARCH];

const getOrganizationDetailsKey = (orgId: any) => [
  QueryKeys.ORGANIZATIONS,
  orgId
];

const getUsersQueryKey = (organizationId: string = '-') => [
  QueryKeys.USERS,
  organizationId
];

const getUsersDetailsQueryKey = (
  organizationId: string = '-',
  userId: string
) => [QueryKeys.USERS, organizationId, userId];

const getConnectionsKey = () => [QueryKeys.CONNECTIONS];

const getClientCredentialsKey = () => [QueryKeys.CLIENT_CREDENTIALS];

const getEnviornmentListsKey = () => [QueryKeys.WORKSPACE_ENVIRONMENTS_LIST];

const getMembersListKey = () => [QueryKeys.MEMBERS_LIST];

const getSubscriptionsQueryKey = () => [QueryKeys.WORKSPACE_SUBSCRIPTIONS];

const getPricingTableQueryKey = () => [QueryKeys.WORKSPACE_PRICING_TABLE];

const getCustomerPortalQueryKey = () => [
  QueryKeys.WORKSPACE_BILLING_CUSTOMER_PORTAL
];

const getOrganizationsConnectionKey = (orgId: any) => [
  QueryKeys.ORGANIZATIONS,
  orgId,
  QueryKeys.CONNECTIONS
];

const getOrganizationsConnectionDetailsKey = (orgId: any, connId: any) => [
  ...getOrganizationsConnectionKey(orgId),
  connId
];

const getOrganizationDirectoriesListQueryKey = (orgId: any) => [
  QueryKeys.ORGANIZATIONS,
  orgId,
  QueryKeys.DIRECTORIES
];

const getOrganizationEventsQueryKey = (directoryId: string) => [
  QueryKeys.ORGANIZATION_EVENTS,
  directoryId
];

const getDirectoryUsersQueryKey = (directoryId: string, pageToken?: string) => {
  const keys = [QueryKeys.DIRECTORY_USERS, directoryId];
  pageToken && keys.push(pageToken);
  return keys;
};

const getDirectoryGroupUsersQueryKey = (
  directoryId: string,
  groupId: string,
  pageToken?
) => {
  const keys = [QueryKeys.DIRECTORY_USERS, directoryId, groupId];
  pageToken && keys.push(pageToken);
  return keys;
};

const getDirectoryGroupsQueryKey = (
  directoryId: string,
  isFromGbra = false
) => [
  isFromGbra
    ? QueryKeys.DIRECTORY_GROUPS_FROM_GBRA
    : QueryKeys.DIRECTORY_GROUPS,
  directoryId
];

const getOrganizationsDirectoryDetailsQueryKey = (
  orgId: any,
  directoryId: any
) => [...getOrganizationDirectoriesListQueryKey(orgId), directoryId];

const getOrganizationsInviteKey = (orgId: any) => [
  ...getOrganizationDetailsKey(orgId),
  QueryKeys.INVITE
];

const getOrganizationsDomainsKey = (orgId: any) => [
  ...getOrganizationDetailsKey(orgId),
  QueryKeys.DOMAINS
];

const getPortalSessionQuery = () => [QueryKeys.PORTAL_SESSION];

const getPortalSessionExpiryQuery = () => [QueryKeys.PORTAL_SESSION_EXPIRY];

const getPortalCustomizationConfigQuery = () => [
  QueryKeys.PORTAL_CUSTOMIZATIONS
];

const getPortalCustomizationConfigForEnvironmentQuery = (envId: string) => [
  QueryKeys.PORTAL_CUSTOMIZATIONS,
  envId
];

const getUserProfileAttributesQuery = () => [QueryKeys.USER_PROFILE_ATTRIBUTES];

const getSocialConnectionListQuery = () => [QueryKeys.SOCIAL_CONNECTIONS];

const getSocialConnectionDetailsQuery = (connectionId?: string) => {
  const query: Array<string | QueryKeys> = [
    QueryKeys.SOCIAL_CONNECTION_DETAILS
  ];
  connectionId && query.push(connectionId);
  return query;
};

const getEnvironmentWebhookPortalQueryKey = () => [
  QueryKeys.ENVIRONMENT_WEBHOOK_PORTAL_LINK
];

const getEnvironmentRolesQueryKey = (environmentId?: string) => [
  QueryKeys.ENVIRONMENT_ROLES,
  environmentId ?? '-'
];

const getWebhookEndpointsQueryKey = (invalidator?: string) =>
  invalidator
    ? [QueryKeys.ENVIRONMENT_WEBHOOK_ENDPOINTS, invalidator]
    : [QueryKeys.ENVIRONMENT_WEBHOOK_ENDPOINTS];

const getWebhookEndpointDetailsQueryKey = (id) => [
  QueryKeys.ENVIRONMENT_WEBHOOK_ENDPOINTS,
  id
];

const getWebhookEventTypesQueryKey = (invalidator?: string) =>
  invalidator
    ? [QueryKeys.ENVIRONMENT_WEBHOOK_EVENT_TYPES, invalidator]
    : [QueryKeys.ENVIRONMENT_WEBHOOK_EVENT_TYPES];

const getCustomDomainDnsRecordsQueryKey = (
  environmentId: string,
  customDomain: string
) => [QueryKeys.CUSTOM_DOMAIN_GET_DNS_RECORDS, environmentId, customDomain];

export {
  getFeatureFlagsQueryKey,
  getResourceContextQueryKey,
  getEnvironmentFeaturesQueryKey,
  getCurrentLoggedInMemeberKey,
  getCurrentWorkspaceKey,
  getOrganizationsKey,
  getOrganizationsSearchKey,
  getOrganizationDetailsKey,
  getUsersQueryKey,
  getUsersDetailsQueryKey,
  getConnectionsKey,
  getClientCredentialsKey,
  getEnviornmentListsKey,
  getMembersListKey,
  getSubscriptionsQueryKey,
  getPricingTableQueryKey,
  getCustomerPortalQueryKey,
  getOrganizationsConnectionKey,
  getOrganizationsConnectionDetailsKey,
  getOrganizationDirectoriesListQueryKey,
  getOrganizationEventsQueryKey,
  getDirectoryUsersQueryKey,
  getDirectoryGroupUsersQueryKey,
  getDirectoryGroupsQueryKey,
  getOrganizationsDirectoryDetailsQueryKey,
  getOrganizationsInviteKey,
  getOrganizationsDomainsKey,
  getPortalSessionQuery,
  getPortalSessionExpiryQuery,
  getUserProfileAttributesQuery,
  getSocialConnectionListQuery,
  getSocialConnectionDetailsQuery,
  getPortalCustomizationConfigQuery,
  getPortalCustomizationConfigForEnvironmentQuery,
  getEnvironmentWebhookPortalQueryKey,
  getEnvironmentRolesQueryKey,
  getWebhookEndpointsQueryKey,
  getWebhookEndpointDetailsQueryKey,
  getWebhookEventTypesQueryKey,
  getCustomDomainDnsRecordsQueryKey
};
