import { createStore } from 'solid-js/store';
import { AppContexts } from '~/utils/commonUtils.ts';
import {
  FeatureFlagKeys,
  FeatureFlagMap
} from '~/api/types/featureFlagData.ts';
import { CodeBlockLanguages } from '~/components/CodeBlock';
import { miscConstants } from '~/consts.ts';
import { OrgDetailsDataType } from '~/components/AppRouter/utils.ts';

// We should be really cautious about the data we store in global store
type GlobalStoreType = {
  currentEnvDomain: string;
  appContext: AppContexts | undefined;
  portalAuthToken: string | null;
  shouldForwardToken: boolean; // Used for backward compatibility of portal auth. Should be removed later
  debugModeEnabled: boolean;
  appFavIcon: string | null;
  shouldShowQuickStartModalOnLoad: boolean;
  directoryBearerTokenPlainTextMap: Record<string, string>; // directoryId as key and token as the value
  featureFlags: FeatureFlagMap;
  codeBlockSelectedLanguage: CodeBlockLanguages;
  adminPortalEnabledModulesFromQueryParam: OrgDetailsDataType[];
  portalSessionExpiry: string;
};

const [globalStore, setGlobalStore] = createStore<GlobalStoreType>({
  currentEnvDomain: '',
  appContext: undefined,
  portalAuthToken: null,
  shouldForwardToken: false,
  debugModeEnabled:
    import.meta.env.MODE === 'development' ||
    window.localStorage.getItem('DEBUG_MODE') === 'on',
  appFavIcon: null,
  shouldShowQuickStartModalOnLoad: false,
  directoryBearerTokenPlainTextMap: {},
  featureFlags: {
    [FeatureFlagKeys.SIGNUP]: {
      key: FeatureFlagKeys.SIGNUP,
      value: import.meta.env.VITE_SIGNUP_ENABLED === 'true'
    }
  },
  codeBlockSelectedLanguage:
    (localStorage.getItem(
      miscConstants.localStorageKeys.getCodeBlockLanguageKey()
    ) as CodeBlockLanguages) || CodeBlockLanguages.JAVASCRIPT,
  adminPortalEnabledModulesFromQueryParam: [],
  portalSessionExpiry: ''
});

export { globalStore, setGlobalStore };
